const tableData = {
    en: {
      grade: <>Grade</>,
      highest: <>highest</>,
      first: <>first</>,
      color: <>Color</>,
      colorFlour: <>White or white with yellow shade</>,
      colorBran: <>Red-yellow with grey shade</>,
      colorSemolina: <>White with creme shade</>,
      moisture: <>Moisture, %, max</>,
      ash: <>Ash content in terms of dry matter, %, max</>,
      gluten: <>Raw gluten content, %, min</>,
      falling: <>Falling number, min</>,
      protein: <>Protein content in terms of dry matter, %</>,
      packaging: <>Packaging</>,
      order: <>Min. order</>,
      orderValue: <>from 20 ton</>,
      kg: <>kg</>,
      sort: <>Sort</>,
      sortValue: <>Refined deodorized winterized sunflower seed oil mark "P"</>,
      clarity: <>Clarity</>,
      clarityValue: <>Transparent, without sediment</>,
      flavor: <>Flavor</>,
      flavorValue: <>impersonal to taste and smell</>,
      colorValue: <>Color value, mg iodine</>,
      acid: <>Acid number, mg KOH/g</>,
      none: <>none</>,
      phosphorus: (
        <>
          Phosphorus-containing substances recalculated as per
          stearoleolecitiny, %
        </>
      ),
      mixtures: <>Non-oil admixtures, %</>,
      moistureVolatile: <>Moisture and volatile substances, %</>,
      peroxide: <>Peroxide number, 1/2 O mmol/kg</>,
      shelfLife: <>Shelf life starting from manufacturing date</>,
      months: <>months</>,
      purpose: <>Product purpose</>,
      purposeValue: <>for food</>,
      energy: <>Energy value (calorie content) in 100g of the product</>,
      energyValue: <>899 kcal (3761 kJ)</>,
      nutritional: <>Nutritional value (food) in 100g of the product:</>,
      nutritionalValue: <>Fat - 99,9 g.</>,
      netWeight: <>Net weight of 1 liter</>,
      netWeightValue: <>0,92 kg</>,
    },
    de: {
      grade: <>Sorte</>,
      highest: <>premium</>,
      first: <>erste</>,
      color: <>Farbe</>,
      colorFlour: <>Weiß oder weiß mit gelbem Schatten</>,
      colorBran: <>Rot-gelb mit grauen Schatten</>,
      colorSemolina: <>Weiß mit cremefarbenen Schatten</>,
      moisture: <>Feuchtigkeit, %, max</>,
      ash: <>Aschegehalt bezogen auf Trockenmasse, %, max</>,
      gluten: <>Rohglutengehalt, %, mind</>,
      falling: <>Fallzahl, mind</>,
      protein: <>Proteingehalt in der Trockenmasse, %</>,
      packaging: <>Verpackung</>,
      order: <>Mind. Bestellung</>,
      orderValue: <>ab 20 Tonnen</>,
      kg: <>kg</>,
      sort: <>Sorte</>,
      sortValue: (
        <>Raffiniertes, desodoriertes, winterfestes Sonnenblumenöl Marke „P“</>
      ),
      clarity: <>Klarheit</>,
      clarityValue: <>Transparent, ohne Sediment</>,
      flavor: <>Geschmack</>,
      flavorValue: <>Unpersönlich zu schmecken und zu riechen</>,
      colorValue: <>Farbwert, mg Jod</>,
      acid: <>Säurezahl, mg KOH/g</>,
      none: <>keine</>,
      phosphorus: (
        <>Phosphorhaltige Substanzen umgerechnet nach Stearolecitiny, %</>
      ),
      mixtures: <>Nicht-Öl-Beimischungen</>,
      moistureVolatile: <>Feuchtigkeit und flüchtige Stoffe, %</>,
      peroxide: <>Peroxidzahl, 1/2 P mmol/kg</>,
      shelfLife: <>Haltbarkeit</>,
      months: <>Monate</>,
      purpose: <>Produktzweck</>,
      purposeValue: <>für Lebensmittel</>,
      energy: <>Energiewert (Kaloriengehalt) von 100 g des Produkts</>,
      energyValue: <>899 kcal (3761 kJ)</>,
      nutritional: <>Nährwert (Lebensmittel) von 100 g des Produkts</>,
      nutritionalValue: <>Fett - 99,9 g.</>,
      netWeight: <>Nettogewicht von 1 Liter</>,
      netWeightValue: <>0,92 kg</>,
    },
    ua: {
      grade: <>Сорт</>,
      highest: <>вищий</>,
      first: <>перший</>,
      color: <>Колір</>,
      colorFlour: <>Білий або білий із жовтим відтінком</>,
      colorBran: <>Червоно-жовтий із сируватим відтінком</>,
      colorSemolina: <>Білий або з кремовим відтінком</>,
      moisture: <>Вологість, %, не більше</>,
      ash: <>Зольність у перерахунку на суху речовину, %, не менше</>,
      gluten: <>Клейковина сира, %, не менше</>,
      falling: <>Число падіння, %, не менше</>,
      protein: <>Білок у перерахунку на суху речовину, %</>,
      packaging: <>Фасування</>,
      order: <>Мінімальне замовлення</>,
      orderValue: <>від 20 тонн</>,
      kg: <>кг</>,
      sort: <>Сорт</>,
      sortValue: (
        <>
          Рафинированное дезодорированное вымороженное подсолнечное масло марки
          "П"
        </>
      ),
      clarity: <>Прозорість</>,
      clarityValue: <>прозорий продукт, без осаду</>,
      flavor: <>Запах та смак</>,
      flavorValue: <>без смаку та запаху</>,
      colorValue: <>Світлота, мг йоду</>,
      acid: <>Кислотне число, мг KOH/г</>,
      none: <>ні</>,
      phosphorus: (
        <>
          Масова частка фосфоровмісних речовин в перерахунку на
          стеароолеолецитин, %
        </>
      ),
      mixtures: <>Масова частка нежирових домішок</>,
      moistureVolatile: <>Масова частка вологи та летких речовин</>,
      peroxide: <>Перекисне число 1/2 О ммоль/кг</>,
      shelfLife: <>Термін придатності починаючи з дати виробництва</>,
      months: <>місяці</>,
      purpose: <>Призначення продукту</>,
      purposeValue: <>для їжі</>,
      energy: <>Енергетична цінність (калорійність) у 100г продукту</>,
      energyValue: <>899 ккал (3761 кДж)</>,
      nutritional: <>Харчова цінність у 100г продукту</>,
      nutritionalValue: <>Жири – 99,9 р.</>,
      netWeight: <>Маса нетто 1 л.</>,
      netWeightValue: <>0,92 кг</>,
    },
    ru: {
      grade: <>Сорт</>,
      highest: <>высший</>,
      first: <>первый</>,
      color: <>Цвет</>,
      colorFlour: <>Белый или белый с желтым оттенком</>,
      colorBran: <>Красно-желтый с сыроватым оттенком</>,
      colorSemolina: <>Белый или с кремовым оттенком</>,
      moisture: <>Влажность, %, не больше</>,
      ash: <>Зольность в перерасчете на сухое вещество, %, не меньше</>,
      gluten: <>Клейковина сырая, %, не меньше</>,
      falling: <>Число падения, %, не меньше</>,
      protein: <>Белок в перерасчёте на сухое вещество, %</>,
      packaging: <>Фасовка</>,
      order: <>Минимальный заказ</>,
      orderValue: <>от 20 тонн</>,
      kg: <>кг</>,
      sort: <>Сорт</>,
      sortValue: (
        <>
          Рафинированное дезодорированное вымороженное подсолнечное масло марки
          "П"
        </>
      ),
      clarity: <>Прозрачность</>,
      clarityValue: <>прозрачный продукт, без осадка</>,
      flavor: <>Привкус</>,
      flavorValue: <>без вкуса и запаха</>,
      colorValue: <>Светлота, мг йода</>,
      acid: <>Кислотное число, мг KOH/г</>,
      none: <>нет</>,
      phosphorus: (
        <>
          Массовая доля фосфоросодержащих веществ в перерасчёте на
          стеароолеолецитин, %
        </>
      ),
      mixtures: <>Массовая доля нежировых примесей</>,
      moistureVolatile: <>Массовая доля воды и летучих веществ</>,
      peroxide: <>Перекисное число 1/2 О ммоль/кг</>,
      shelfLife: <>Срок годности начиная с даты производства</>,
      months: <>месяца</>,
      purpose: <>Назначение продукта</>,
      purposeValue: <>для еды</>,
      energy: <>Энергетическая ценность (калорийность) в 100г продукта</>,
      energyValue: <>899 ккал (3761 кДж)</>,
      nutritional: <>Пищевая ценность в 100 г. продукта</>,
      nutritionalValue: <>Жиры - 99,9 г.</>,
      netWeight: <>Масса нетто 1 л.</>,
      netWeightValue: <>0,92 кг</>,
    },
  };

  export default tableData;